import { twMerge } from '@troon/tailwind-preset/merge';
import type { ComponentProps, ParentProps } from 'solid-js';

export function Grid(props: ParentProps) {
	return <div class="grid w-full grid-cols-1 gap-4 lg:grid-cols-12 lg:gap-12">{props.children}</div>;
}

export function GridFull(props: ComponentProps<'div'>) {
	return <div {...props} class={twMerge('col-span-1 lg:col-span-12', props.class)} />;
}

export function GridHalf(props: ComponentProps<'div'> & { border?: boolean }) {
	return (
		<div
			{...props}
			class={twMerge('col-span-1 lg:col-span-6', props.border && 'rounded-lg border border-neutral-500', props.class)}
		/>
	);
}

export function GridThird(props: ComponentProps<'div'> & { border?: boolean }) {
	return (
		<div
			{...props}
			class={twMerge('col-span-1 lg:col-span-4', props.border && 'rounded-lg border border-neutral-500', props.class)}
		/>
	);
}

export function GridTwoThirds(props: ComponentProps<'div'> & { border?: boolean }) {
	return (
		<div
			{...props}
			class={twMerge('col-span-1 lg:col-span-8', props.border && 'rounded-lg border border-neutral-500', props.class)}
		/>
	);
}

export function GridMain(props: ComponentProps<'div'> & { border?: boolean }) {
	return (
		<div
			{...props}
			class={twMerge('col-span-1 lg:col-span-8', props.border && 'rounded-lg border border-neutral-500', props.class)}
		/>
	);
}

export function GridSidebar(props: ComponentProps<'div'> & { border?: boolean }) {
	return (
		<div
			{...props}
			class={twMerge(
				'col-span-1 lg:col-span-4 lg:row-start-auto',
				props.border && 'rounded-lg border border-neutral-500',
				props.class,
			)}
		/>
	);
}
